import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { ImageProp, ImageStyles } from "@business-finland/wif-ui-lib";
import {
  IRecommendationsCardProps,
  Recommendations as RecommendationsUi,
} from "@business-finland/wif-ui-lib/dist/Recommendations";
import { useTranslation } from "next-i18next";

import { Viewport } from "../../../constants/app";
import useSavedItems from "../../../contexts/SaveItemsToStorageContext";
import useEmployerSite from "../../../contexts/EmployerSiteContext";
import useImageDataHandler from "../../../hooks/useImageDataHandler";
import { formatPageLink } from "../../../util/magnolia";
import { mapMgnlPageToSavedItem } from "../../../util/saved-items";

interface IRecommendationsProps
  extends Wif.Mgnl.ComponentProps<{
    title: string;
    matchingNodes: (Wif.Mgnl.NavPage | Wif.Mgnl.EventPage)[];
  }> {}

export function Recommendations({
  title,
  matchingNodes,
}: IRecommendationsProps): JSX.Element | null {
  const mapNodeToCardProps = useMapNodeToRecommendationCardProps();

  const cards = useMemo(
    () => matchingNodes.map(mapNodeToCardProps),
    [matchingNodes, mapNodeToCardProps]
  )
    .sort((a, b) => a.date && b.date ? a.date.getTime() - b.date.getTime() : 0);
  ;

  if (cards.length === 0) return null;

  return <RecommendationsUi cards={cards} title={title} />;
}

function useMapNodeToRecommendationCardProps(): (
  node: Wif.Mgnl.NavPage | Wif.Mgnl.EventPage
) => IRecommendationsCardProps {
  const { isPreview } = useRouter();
  const { t } = useTranslation();
  const { isEmployerSite } = useEmployerSite();
  const { isSaved: isItemSaved, toggleItemInLocalStorage } = useSavedItems();
  const imageDataHandler = useImageDataHandler();

  const handleSaveItemClick = useCallback(
    (node: Wif.Mgnl.NavPage) => {
      if (!node) return;

      toggleItemInLocalStorage(mapMgnlPageToSavedItem(node));
    },
    [toggleItemInLocalStorage]
  );

  return useCallback(
    (node) => {
      const id = node["@id"];
      const date = node.eventDateStart
        ? new Date(node.eventDateStart)
        : undefined;
      const dateEnd = node.eventDateEnd
        ? new Date(node.eventDateEnd)
        : undefined;
      const imageData = imageDataHandler(node?.introductionImage, {
        maxViewport: Viewport.xs,
      });
      const image: ImageProp = {
        ...imageData,
        altText: node.title,
        styling: ImageStyles.CARD,
        credits: node.introductionImage?.metadata?.rights,
      };

      return {
        id,
        title: node.title,
        description: node.description,
        image,
        date,
        dateEnd,
        showSaveButton: !isEmployerSite,
        link: formatPageLink(node["@path"], isPreview),
        isSaved: isItemSaved(id),
        onClick: () => handleSaveItemClick(node),
        i18n: {
          date: t("dates.date"),
          startFrom: t("dates.startFrom"),
        },
      };
    },
    [
      imageDataHandler,
      isPreview,
      isItemSaved,
      t,
      handleSaveItemClick,
      isEmployerSite,
    ]
  );
}
