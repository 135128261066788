import { ISavedItem } from "../types/saved-items";

export const mapMgnlPageToSavedItem = (page: Wif.Mgnl.NavPage): ISavedItem => {
  const id = page["@id"];
  const pageLink = page["@path"];
  const title = page.title;
  const description = page.description;
  const image = page.introductionImage;

  return { id, title, description, pageLink, image, type: "masonryCard" };
};
